import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles, lighten, makeStyles } from "@material-ui/core/styles";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Input from "@material-ui/core/Input";
import { Button } from "@material-ui/core";
import {
  getlotterylist,
  completeTicket,
  closeComTicket,
} from "./../../actions/admin";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { toast } from "react-toastify";

import Web3 from "web3";
import "@metamask/legacy-web3";

import lotteryABI from "../../ABI/Lottery.json";

import config from "../../lib/config";

const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "lotteryId", numeric: false, disablePadding: true, label: "LotteryId" },
  { id: "endTime", numeric: true, disablePadding: false, label: "End Time" },
  {
    id: "ticketPice",
    numeric: true,
    disablePadding: false,
    label: "ticketPice",
  },
  // { id: "discount", numeric: true, disablePadding: false, label: "Discount" },
  { id: "fees", numeric: true, disablePadding: false, label: "Fees" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  // {
  //   id: "created_date",
  //   numeric: true,
  //   disablePadding: false,
  // },
  { id: "Action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            // padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const customStyles = customStyle();
  const classes = useStyles();
  const history = useHistory();
  const classesSearch = useToolbarStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [userdet, setUser] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [responsive, setresponsive] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getLotteryList = async () => {
    var test = await getlotterylist();
    setUser(test.userValue);
  };

  const add = async () => {
    history.push("/startLottery");
  };

  async function complete(data) {
    console.log(data, "datadatatatataatatat");
    var id = data._id;
    if (id != "") {
      if (!window.ethereum) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
      var web3 = new Web3(window.ethereum);
      var currAddr = window.web3.eth.defaultAccount;
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }

      const Contract = new web3.eth.Contract(
        lotteryABI,
        config.ContractAddress
      );
      var result = await Contract.methods
        .drawFinalNumberAndMakeLotteryClaimable(data.lotteryId, true)
        .send({ from: currAddr });

      var result1 = await completeTicket({ id });
      toast.success("Ticket Completed Successfully", toasterOption);
      getLotteryList();
    }
  }

  async function closeTicket(data) {
    var id = data._id;
    if (id != "") {
      if (!window.ethereum) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
      var web3 = new Web3(window.ethereum);
      var currAddr = window.web3.eth.defaultAccount;
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }

      const Contract = new web3.eth.Contract(
        lotteryABI,
        config.ContractAddress
      );
      var result = await Contract.methods
        .closeLottery(data.lotteryId)
        .send({ from: currAddr });
      console.log(id, "idididid");
      var result1 = await closeComTicket({ id });
      toast.success("Ticket closed successfully", toasterOption);
      getLotteryList();
    }
  }

  useEffect(() => {
    getLotteryList();
  }, []);
  const columns = [
    {
      key: "lotteryId",
      text: "Lottery Id",
      className: "answer",
      align: "left",
      sortable: false,
    },
    {
      key: "endTime",
      text: "End Time",
      className: "answer",
      align: "left",
      sortable: false,
    },
    {
      key: "ticketPice",
      text: "Ticket Price",
      className: "answer",
      align: "left",
      sortable: false,
    },
    // {
    //   key: "fees",
    //   text: "Fees",
    //   className: "answer",
    //   align: "left",
    //   sortable: false,
    // },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: (record) => {
        if (record.status == 0 && new Date(record.endDate) > new Date()) {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                Open
              </button>
            </Fragment>
          );
        } else if (record.status == 0) {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => closeTicket(record)}
              >
                Close
              </button>
            </Fragment>
          );
        } else if (record.status == 1) {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                onClick={() => complete(record)}
                style={{ marginRight: "5px" }}
              >
                Complete
              </button>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                Completed
              </button>
            </Fragment>
          );
        }
      },
    },
  ];
  const configdata = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  };

  function pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  return (
    <div className={classes.root}>
      <div className="page_header">
        <h2>Lottery List</h2>
        <div>
          {/* <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={choose}
                    onChange={handleChange}>
                        <MenuItem value={"Daily"}>Daily</MenuItem>
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Custom"}>Custom</MenuItem>
                    </Select>
                    <CSVLink data={csvData} > <button
                       className="ml-3" variant="contained" color="primary"
                    >
                     Download
                    </button>
                    </CSVLink> */}
          {/* <Button className="ml-3" variant="contained" color="primary" onClick={download}>Download</Button> */}
          <Button
            className="Button"
            variant="contained"
            color="primary"
            onClick={add}
          >
            Add
          </Button>
        </div>
      </div>
      <Paper className={classes.paper}>
        <ReactDatatable
          responsive={responsive}
          config={configdata}
          records={userdet}
          columns={columns}
          onPageChange={pageChange()}
        />
      </Paper>
      {/*  <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />*/}
    </div>
  );
}
